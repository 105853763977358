/* eslint-disable camelcase */
import { createNamespacedHelpers } from 'vuex'
// import _get from 'lodash/get'
import { LIMIT_TO_CREATE_NEW_ADS_ACCOUNT } from '@/constants'

const { mapGetters: mapUserGetter } = createNamespacedHelpers('auth')
const { mapGetters: mapAdsAccountGetter } = createNamespacedHelpers('adsAccount')

export default {
  data() {
    return {
      hasAdsAccounts: false,
      hasTickets: false,
    }
  },

  computed: {
    ...mapUserGetter(['user']),
    ...mapAdsAccountGetter(['listBusinessAccounts', 'listTicket', 'tiktokAccountCurrent']),

    tiktokConnectInfo() {
      return this.tiktokAccountCurrent
    },

    hasBalance() {
      if (!this.user?.data) {
        return false
      }

      return this.user.data?.balance >= LIMIT_TO_CREATE_NEW_ADS_ACCOUNT
    },

    hasConnectTiktok() {
      if (this.tiktokAccountCurrent?.id) {
        return true
      }

      return false
    },

    hasBusinessAccount() {
      if (this.listBusinessAccounts && this.listBusinessAccounts.list) {
        return this.listBusinessAccounts.list.length !== 0
      }

      return false
    },

    hasTicketList() {
      if (this.listTicket && this.listTicket.content) {
        return this.listTicket.content.length !== 0
      }
      return false
    },
  },

  watch: {
    user: {
      handler(val) {
        this.hasAdsAccounts = Array.isArray(val.data?.adsAccounts) && !!val.data?.adsAccounts?.length
      },
      deep: true,
      immediate: true,
    },
    listTicket(value) {
      this.hasTickets = !!value.content?.length
    },
  },
}
